angular.module(
  'ngLocale',
  [],
  [
    '$provide',
    function ($provide) {
      const PLURAL_CATEGORY = {
        ZERO: 'zero',
        ONE: 'one',
        TWO: 'two',
        FEW: 'few',
        MANY: 'many',
        OTHER: 'other',
      };
      $provide.value('$locale', {
        NUMBER_FORMATS: {
          DECIMAL_SEP: ',',
          GROUP_SEP: '.',
          PATTERNS: [
            {
              minInt: 1,
              minFrac: 0,
              macFrac: 0,
              posPre: '',
              posSuf: '',
              negPre: '-',
              negSuf: '',
              gSize: 3,
              lgSize: 3,
              maxFrac: 3,
            },
            {
              minInt: 1,
              minFrac: 2,
              macFrac: 0,
              posPre: '\u00A4 ',
              posSuf: '',
              negPre: '\u00A4 ',
              negSuf: '-',
              gSize: 3,
              lgSize: 3,
              maxFrac: 2,
            },
          ],
          CURRENCY_SYM: '€',
        },
        pluralCat: function (n) {
          if (n == 1) {
            return PLURAL_CATEGORY.ONE;
          }
          return PLURAL_CATEGORY.OTHER;
        },
        DATETIME_FORMATS: {
          MONTH: [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'oktober',
            'november',
            'december',
          ],
          SHORTMONTH: [
            'jan.',
            'feb.',
            'mrt.',
            'apr.',
            'mei',
            'jun.',
            'jul.',
            'aug.',
            'sep.',
            'okt.',
            'nov.',
            'dec.',
          ],
          DAY: [
            'zondag',
            'maandag',
            'dinsdag',
            'woensdag',
            'donderdag',
            'vrijdag',
            'zaterdag',
          ],
          SHORTDAY: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
          AMPMS: ['AM', 'PM'],
          medium: 'd MMM y HH:mm:ss',
          short: 'dd-MM-yy HH:mm',
          fullDate: 'EEEE d MMMM y',
          longDate: 'd MMMM y',
          mediumDate: 'd MMM y',
          shortDate: 'dd-MM-yy',
          mediumTime: 'HH:mm:ss',
          shortTime: 'HH:mm',
        },
        id: 'nl-nl',
      });
    },
  ],
);
